import config from '../config'
import { keyedConst, descConst, promiseActions } from './util'

export const EMPTY_RESPONSE = {}

export const actions = {
  ...keyedConst('CLEAR_PENDING'),
  ...keyedConst('CLEAR_ERROR'),
  ...keyedConst('CLEAR_SUCCESS'),

  // API actions
  ...promiseActions('FETCH_PRO_PROGRAMS'),

  ...promiseActions('LOGIN'),
  ...promiseActions('LOGOUT'),
  ...promiseActions('FETCH_SELF'),

  ...promiseActions('FETCH_CONSTS'),
  ...promiseActions('FETCH_ALL_CONSTS'),
  ...promiseActions('FETCH_CONSTS_BY_NAME'),

  ...promiseActions('SET_ORG'),
  ...promiseActions('SEARCH_ORGS'),
  ...promiseActions('FETCH_ORGS'),
  ...promiseActions('FETCH_ORG'),
  ...promiseActions('UPDATE_ORG_SETTINGS'),
  ...promiseActions('ADD_ORG_TEAM_MEMBERSHIP'),
  ...promiseActions('REMOVE_ORG_TEAM_MEMBERSHIP'),

  ...promiseActions('FETCH_CLUBS'),
  ...promiseActions('FETCH_CLUB'),
  ...promiseActions('FETCH_CLUB_MEMBERS'),
  ...promiseActions('CREATE_CLUB'),
  ...promiseActions('EDIT_CLUB'),
  ...promiseActions('ADD_CLUB_MEMBER'),
  ...promiseActions('DOWNLOAD_CLUB_MEMBERS_CSV'),
  ...promiseActions('REMOVE_CLUB_MEMBER'),
  ...promiseActions('EDIT_CLUB_MEMBER'),
  ...promiseActions('CREATE_CLUB_POST'),
  ...promiseActions('EDIT_CLUB_POST'),
  ...promiseActions('FETCH_CLUB_POST'),
  ...promiseActions('DELETE_CLUB_POST'),
  ...promiseActions('DELETE_CLUB'),
  ...promiseActions('EDIT_CLUB_POST'),
  ...promiseActions('PIN_CLUB_POST'),
  ...promiseActions('UNPIN_CLUB_POST'),
  ...promiseActions('FETCH_CLUB_POSTS'),
  ...promiseActions('FETCH_CLUB_PINNED_POSTS'),
  ...promiseActions('UPDATE_CLUB_SETTINGS'),
  ...promiseActions('FETCH_CLUB_PRODUCTS'),
  ...promiseActions('SET_CLUB_PRODUCTS'),
  ...promiseActions('FETCH_CLUB_VIDEOS'),
  ...promiseActions('FETCH_CLUB_VIDEO'),
  ...promiseActions('UPDATE_CLUB_VIDEO'),
  ...promiseActions('CREATE_CLUB_VIDEO'),
  ...promiseActions('UPDATE_CLUB_VIDEOS_ORDER'),
  ...promiseActions('DELETE_CLUB_VIDEO'),

  ...promiseActions('FETCH_SPONSORS'),
  ...promiseActions('FETCH_SPONSOR'),
  ...promiseActions('CREATE_SPONSOR'),
  ...promiseActions('UPDATE_SPONSOR'),
  ...promiseActions('DELETE_SPONSOR'),

  ...promiseActions('FETCH_BENEFITS'),
  ...promiseActions('FETCH_ADMIN_BENEFITS'),
  ...promiseActions('FETCH_ADMIN_BENEFIT'),
  ...promiseActions('SEARCH_BENEFITS'),
  ...promiseActions('FETCH_BENEFICIARY_GROUP'),
  ...promiseActions('FETCH_BENEFICIARY_GROUP_USERS'),
  ...promiseActions('FETCH_BENEFICIARY_GROUPS'),
  ...promiseActions('CREATE_BENEFICIARY_GROUP'),
  ...promiseActions('UPDATE_BENEFICIARY_GROUP'),
  ...promiseActions('REMOVE_BENEFICIARY_GROUP'),
  ...promiseActions('MANUALLY_ADD_USER_TO_BENFICIARY_GROUP'),
  ...promiseActions('ADD_BENEFIT_VENUE'),
  ...promiseActions('UPDATE_BENEFIT_VENUE'),
  ...promiseActions('DELETE_BENEFIT_VENUE'),

  ...promiseActions('CREATE_ORG'),
  ...promiseActions('UPDATE_ORG'),
  ...promiseActions('FETCH_ALL_ORGS'),

  ...promiseActions('FETCH_VENUE'),
  ...promiseActions('FETCH_ALL_VENUES'),
  ...promiseActions('SEARCH_VENUES'),
  ...promiseActions('CREATE_VENUE'),
  ...promiseActions('UPDATE_VENUE'),
  ...promiseActions('UPDATE_VENUE_SETTINGS'),
  ...promiseActions('FETCH_VENUE_RESOURCES'),
  ...promiseActions('UPDATE_BENEFIT_VENUE_SETTINGS'),
  ...promiseActions('UPDATE_BENEFIT_VENUE_PERMITTED_RESOURCES'),
  ...promiseActions('UPDATE_BENEFIT_VENUE_PERMITTED_BENEFICIARY_GROUPS'),
  ...promiseActions('UPDATE_VENUE_HOMEPAGE_SETTINGS'),
  ...promiseActions('CREATE_VENUE_AVAILABLE_LESSON'),
  ...promiseActions('DELETE_VENUE_AVAILABLE_LESSON'),
  ...promiseActions('FETCH_VENUE_HOMEPAGE_CALLOUTS'),
  ...promiseActions('CREATE_VENUE_HOMEPAGE_CALLOUT'),
  ...promiseActions('UPDATE_VENUE_HOMEPAGE_CALLOUT'),
  ...promiseActions('DELETE_VENUE_HOMEPAGE_CALLOUT'),
  ...promiseActions('UPDATE_VENUE_HOMEPAGE_CALLOUTS_ORDER'),

  ...promiseActions('FETCH_ORG_TAGS'),
  ...promiseActions('FETCH_ORG_TAG'),
  ...promiseActions('CREATE_ORG_TAG'),
  ...promiseActions('UPDATE_ORG_TAG'),
  ...promiseActions('DELETE_ORG_TAG'),

  ...promiseActions('SET_PROGRAM_ORG_TAGS'),

  ...promiseActions('FETCH_CLUB_POST_COMMENTS'),
  ...promiseActions('CREATE_CLUB_POST_COMMENT'),
  ...promiseActions('DELETE_CLUB_POST_COMMENT'),
  ...promiseActions('UPDATE_CLUB_POST_COMMENT'),
  ...promiseActions('CREATE_CLUB_POST_COMMENT_REPLY'),

  ...promiseActions('FETCH_ROLES'),
  ...promiseActions('FETCH_ROLE'),
  ...promiseActions('ASSIGN_ROLE_TO_USER'),
  ...promiseActions('UNASSIGN_ROLE_FROM_USER'),

  ...promiseActions('CREATE_STRIPE_SETUP_INTENT'),
  ...promiseActions('CONFIRM_STRIPE_CARD_SETUP'),

  ...keyedConst('SET_DRAFT_PROGRAM'),
  ...keyedConst('CLEAR_DRAFT_PROGRAM'),
  ...promiseActions('CREATE_PROGRAM_DRAFT'),
  ...promiseActions('FETCH_DRAFT_PROGRAM'),

  ...promiseActions('CREATE_PROGRAM_POST'),
  ...promiseActions('FETCH_PROGRAM_POST'),
  ...promiseActions('FETCH_PROGRAM_POSTS'),
  ...promiseActions('FETCH_PROGRAM_PINNED_POSTS'),
  ...promiseActions('DELETE_PROGRAM_POST'),
  ...promiseActions('EDIT_PROGRAM_POST'),
  ...promiseActions('PIN_PROGRAM_POST'),
  ...promiseActions('UNPIN_PROGRAM_POST'),
  ...promiseActions('FETCH_PROGRAM_POST_COMMENTS'),
  ...promiseActions('CREATE_PROGRAM_POST_COMMENT'),
  ...promiseActions('DELETE_PROGRAM_POST_COMMENT'),
  ...promiseActions('UPDATE_PROGRAM_POST_COMMENT'),
  ...promiseActions('CREATE_PROGRAM_POST_COMMENT_REPLY'),

  ...promiseActions('UPDATE_PROGRAM'),
  ...promiseActions('FINALIZE_DRAFT_PROGRAM'),
  ...promiseActions('UPDATE_ADMIN_PROGRAM'),
  ...promiseActions('USER_ADMIN_CANCEL_RES_RESERVATION_BOOKINGS'),
  ...promiseActions('USER_ADMIN_CANCEL_PROGRAM_BOOKINGS'),
  ...promiseActions('CANCEL_PROGRAM'),
  ...promiseActions('FETCH_PROGRAMS'),
  ...promiseActions('FETCH_PAST_PROGRAMS'),
  ...promiseActions('FETCH_DRAFT_PROGRAMS'),
  ...promiseActions('DELETE_PROGRAM'),
  ...promiseActions('CANCEL_PROGRAM_BOOKING'),
  ...promiseActions('CHANGE_BOOKING_NOTE'),
  ...promiseActions('DUPLICATE_PROGRAM'),
  ...promiseActions('DOWNLOAD_PROGRAM_BOOKINGS_CSV'),

  ...promiseActions('FETCH_CURRENT_PROGRAM'),
  ...promiseActions('FETCH_PROGRAM'),

  ...promiseActions('ADD_TO_PROGRAM_WAITLIST'),
  ...promiseActions('UPDATE_WAITLIST_USER'),
  ...promiseActions('REMOVE_FROM_PROGRAM_WAITLIST'),

  ...promiseActions('FETCH_CUSTOM_LANDING_PAGES'),
  ...promiseActions('CREATE_CUSTOM_LANDING_PAGE'),
  ...promiseActions('UPDATE_CUSTOM_LANDING_PAGE'),
  ...promiseActions('DELETE_CUSTOM_LANDING_PAGE'),

  ...promiseActions('FETCH_CUSTOM_EMAIL_CAPTURES'),
  ...promiseActions('FETCH_CUSTOM_EMAIL_CAPTURE'),
  ...promiseActions('CREATE_CUSTOM_EMAIL_CAPTURE'),
  ...promiseActions('UPDATE_CUSTOM_EMAIL_CAPTURE'),
  ...promiseActions('DELETE_CUSTOM_EMAIL_CAPTURE'),

  ...promiseActions('FETCH_INTERNAL_TAGS'),
  ...promiseActions('CREATE_INTERNAL_TAG'),
  ...promiseActions('DELETE_INTERNAL_TAG'),

  ...promiseActions('FETCH_LOCATION'),
  ...promiseActions('FETCH_LOCATIONS'),
  ...promiseActions('CREATE_LOCATION'),
  ...promiseActions('UPDATE_LOCATION'),
  ...promiseActions('DELETE_LOCATION'),

  ...promiseActions('FETCH_ATTRIBUTES'),
  ...promiseActions('FETCH_PROGRAM_ATTRIBUTES'),
  ...promiseActions('CREATE_ATTRIBUTE'),
  ...promiseActions('DELETE_ATTRIBUTE'),
  ...promiseActions('UPDATE_ATTRIBUTE'),
  ...promiseActions('FETCH_CLUB_ATTRIBUTES'),

  ...promiseActions('FETCH_CLUB_FILTERS'),
  ...promiseActions('SEARCH_CLUBS'),
  ...promiseActions('QUICK_SEARCH_CLUBS'),

  ...promiseActions('FETCH_PROGRAMS_SEARCH'),
  ...promiseActions('FETCH_UPCOMING_PROGRAMS'),
  ...promiseActions('SEARCH_UPCOMING_PROGRAMS'),
  ...promiseActions('SEARCH_PAST_PROGRAMS'),
  ...promiseActions('QUICK_SEARCH_PROGRAMS'),
  ...promiseActions('FETCH_USERS_SEARCH'),
  ...promiseActions('FETCH_USER'),
  ...promiseActions('FETCH_USER_PROGRAM_BOOKINGS'),
  ...promiseActions('FETCH_USER_PACKAGES'),
  ...promiseActions('FETCH_USER_PACKAGE'),
  ...promiseActions('COUNT_USER_PACKAGE_ITEM_USES'),
  ...promiseActions('UPDATE_USER_PACKAGE_ITEM'),
  ...promiseActions('FETCH_USER_CLUB_MEMBERSHIPS'),
  ...promiseActions('FETCH_USER_WAITLISTS'),
  ...promiseActions('FETCH_USER_ORG_TEAM_MEMBERSHIPS'),
  ...promiseActions('FETCH_USER_VENUE_TEAM_MEMBERSHIPS'),
  ...promiseActions('REMOVE_USER_VENUE_TEAM_MEMBERSHIP'),
  ...promiseActions('ADD_USER_VENUE_TEAM_MEMBERSHIP'),
  ...promiseActions('REMOVE_USER_ORG_TEAM_MEMBERSHIP'),
  ...promiseActions('ADD_USER_ORG_TEAM_MEMBERSHIP'),
  ...promiseActions('ADD_ORG_FEE_RULE'),
  ...promiseActions('REMOVE_ORG_FEE_RULE'),
  ...promiseActions('CREATE_USER'),
  ...promiseActions('GENERATE_USER_PASSWORD_RESET_LINK'),

  ...promiseActions('GET_REGISTERED_EMAILS'),
  ...promiseActions('GENERATE_EMAIL_PREVIEW'),
  ...promiseActions('SEND_EMAIL'),
  ...promiseActions('FETCH_USERS'),
  ...promiseActions('UPDATE_USER'),
  ...promiseActions('UPDATE_USER_TYPE'),
  ...promiseActions('UPDATE_USER_LEVEL'),
  ...promiseActions('UPDATE_USER_PROFILE_IMAGE'),
  ...promiseActions('FETCH_PRO_PAGE'),
  ...promiseActions('CREATE_PRO_PAGE'),
  ...promiseActions('UPDATE_PRO_PAGE'),
  ...promiseActions('DELETE_PRO_PAGE'),
  ...promiseActions('ADD_PRO_PAGE_IMAGES'),
  ...promiseActions('DELETE_PRO_PAGE_IMAGE'),
  ...promiseActions('FETCH_PRO_SHOP'),
  ...promiseActions('CREATE_PRO_SHOP'),
  ...promiseActions('UPDATE_PRO_SHOP'),
  ...promiseActions('DELETE_PRO_SHOP'),
  ...promiseActions('ADD_PRO_SHOP_ITEM'),
  ...promiseActions('DELETE_PRO_SHOP_ITEM'),
  ...promiseActions('UPDATE_PRO_SHOP_ITEM'),
  ...promiseActions('ADD_PRO_SHOP_ITEM_IMAGES'),
  ...promiseActions('DELETE_PRO_SHOP_ITEM_IMAGE'),

  ...promiseActions('FETCH_ALL_PROMO_CODES'),
  ...promiseActions('FETCH_PROMO_CODE'),
  ...promiseActions('CREATE_PROMO_CODE'),
  ...promiseActions('UPDATE_PROMO_CODE'),
  ...promiseActions('DELETE_PROMO_CODE'),
  ...promiseActions('ACTIVATE_PROMO_CODE'),
  ...promiseActions('DEACTIVATE_PROMO_CODE'),

  ...promiseActions('CREATE_MANUAL_BOOKING'),

  ...promiseActions('FETCH_INVOICE'),
  ...promiseActions('UPDATE_INVOICE_NOTE'),

  ...promiseActions('UPDATE_PAYMENT_NOTE'),
  ...promiseActions('UPDATE_LINE_ITEM_NOTE'),
  ...promiseActions('REFUND_PAYMENT'),

  ...promiseActions('FETCH_NYC_PERMIT_ACCOUNTS'),
  ...promiseActions('FETCH_NYC_PERMIT_ACCOUNT'),
  ...promiseActions('FETCH_NYC_PERMIT_CARDS'),
  ...promiseActions('FETCH_NYC_PERMIT_CARD'),
  ...promiseActions('CREATE_NYC_PERMIT_ACCOUNT'),
  ...promiseActions('CREATE_NYC_PERMIT_CARD'),
  ...promiseActions('UPDATE_NYC_PERMIT_ACCOUNT'),
  ...promiseActions('UPDATE_NYC_PERMIT_CARD'),
  ...promiseActions('FETCH_PURCHASABLE_PERMITS'),
  ...promiseActions('UPDATE_PURCHASABLE_PERMIT'),

  ...promiseActions('FETCH_ALL_EXPLORE_CLUBS_POSITIONS'),
  ...promiseActions('CREATE_CLUB_POSITION'),
  ...promiseActions('UPDATE_CLUB_POSITION'),
  ...promiseActions('DELETE_CLUB_POSITION'),
  ...promiseActions('FETCH_ALL_CLUBS'),

  ...promiseActions('FETCH_BTL_FEATURED_GEOS'),
  ...promiseActions('CREATE_BTL_FEATURED_GEO'),
  ...promiseActions('UPDATE_BTL_FEATURED_GEO'),
  ...promiseActions('REORDER_BTL_FEATURED_GEOS'),
  ...promiseActions('DELETE_BTL_FEATURED_GEO'),

  ...promiseActions('FETCH_BTL_FEATURED_FACILITIES'),
  ...promiseActions('CREATE_BTL_FEATURED_FACILITY'),
  ...promiseActions('UPDATE_BTL_FEATURED_FACILITY'),
  ...promiseActions('REORDER_BTL_FEATURED_FACILITIES'),
  ...promiseActions('DELETE_BTL_FEATURED_FACILITY'),

  ...promiseActions('FETCH_ALL_USER_PERMITS'),
  ...promiseActions('FETCH_USER_PERMIT'),
  ...promiseActions('FETCH_USER_USER_PERMITS'),
  ...promiseActions('EDIT_USER_PERMIT_NOTES'),
  ...promiseActions('SET_USER_PERMIT_NYC_ACCOUNT'),
  ...promiseActions('SET_USER_PERMIT_NYC_CARD'),
  ...promiseActions('MANUALLY_FULFILL_USER_PERMIT'),
  ...promiseActions('DOWNLOAD_USER_PERMITS_CSV'),

  ...promiseActions('FETCH_USER_BENEFICIARIES'),
  ...promiseActions('FETCH_USER_CUSTOMERS'),
  ...promiseActions('UPDATE_BENEFICIARY'),

  ...promiseActions('FETCH_ALL_PRODUCTS'),
  ...promiseActions('FETCH_PRODUCT'),
  ...promiseActions('CREATE_PRODUCT'),
  ...promiseActions('DELETE_PRODUCT'),
  ...promiseActions('EDIT_PRODUCT'),
  ...promiseActions('FETCH_PRODUCT_VARIANT_STOCK'),
  ...promiseActions('ADD_VARIANT_STOCK'),
  ...promiseActions('REMOVE_VARIANT_STOCK'),
  ...promiseActions('FETCH_PRODUCT_VARIANT_OPTIONS'),
  ...promiseActions('FETCH_PRODUCT_VARIANTS'),

  ...promiseActions('FETCH_ALL_VARIANT_OPTIONS'),
  ...promiseActions('CREATE_VARIANT_OPTION'),
  ...promiseActions('CHANGE_PRODUCT_VARIANT_OPTIONS'),
  ...promiseActions('CHANGE_PRODUCT_CLUBS'),

  ...promiseActions('FETCH_ALL_ORDERS'),
  ...promiseActions('FETCH_ORDER'),
  ...promiseActions('UPDATE_ORDER'),
  ...promiseActions('UPDATE_ORDER_ITEM'),

  ...promiseActions('ESTIMATE_INVOICE'),
  ...promiseActions('CANCEL_INVOICE'),
  ...promiseActions('CAPTURE_PAYMENT'),
  ...promiseActions('EMAIL_RECEIPT'),

  ...promiseActions('TERMINAL_CREATE_CONNECTION_TOKEN'),
  ...promiseActions('TERMINAL_START_ORDER'),

  /* Packages */
  ...promiseActions('FETCH_PACKAGE'),
  ...promiseActions('FETCH_PACKAGE_SETS'),
  ...promiseActions('FETCH_PACKAGE_SET'),
  ...promiseActions('CREATE_PACKAGE_SET'),
  ...promiseActions('UPDATE_PACKAGE_SET'),
  ...promiseActions('DELETE_PACKAGE_SET'),

  /* Program res reservation */
  ...promiseActions('CREATE_PROGRAM_RES_RESERVATIONS'),
  ...promiseActions('DELETE_PROGRAM_RES_RESERVATION'),

  /* Program benefits */
  ...promiseActions('FETCH_PROGRAM_BENEFITS'),
  ...promiseActions('ADD_BENEFIT_PROGRAM'),
  ...promiseActions('UPDATE_BENEFIT_PROGRAM'),
  ...promiseActions('REMOVE_BENEFIT_PROGRAM'),

  /* Walmart */
  ...promiseActions('WALMART_PRODUCT_LOOKUP'),

  /* BTL Network */
  ...promiseActions('FETCH_NETWORK_VENUES'),
  ...promiseActions('FETCH_NETWORK_VENUE_AVAILABLE_RESOURCES'),

  /* Metrics */
  ...promiseActions('METRIC_PER_RESOURCE_BOOKINGS_BY_MONTH'),
  ...promiseActions('METRIC_PER_RESOURCE_BOOKINGS_BY_MONTH_QUERY'),
  ...promiseActions('METRIC_PER_PARTICIPANT_BOOKINGS_BY_MONTH'),
  ...promiseActions('METRIC_PER_PARTICIPANT_BOOKINGS_BY_MONTH_QUERY'),

  /* Inventory stats */
  ...promiseActions('DOWNLOAD_INVENTORY_CAPACITY'),
}

export const S3_ASSET_URL_BASE = `https://${config.s3AssetBucket}.s3.${config.s3AssetBucketRegion}.amazonaws.com`

export const features = {
  program: {
    url: 'programs',
    name: {
      plural: 'Events',
      singular: 'Event',
    },
  },
  location: {
    url: 'locations',
    name: {
      plural: 'Locations',
      singular: 'Location',
    },
  },
  club: {
    url: 'clubs',
    name: {
      plural: 'Clubs',
      singular: 'Club',
    },
  },
  product: {
    url: 'products',
    name: {
      plural: 'Products',
      singular: 'Product',
    },
  },
  shop: {
    url: 'shops',
    name: {
      plural: 'Shops',
      singular: 'Shop',
    },
  },
  role: {
    url: 'roles',
    name: {
      plural: 'Roles',
      singular: 'Role',
    },
  },
  sponsor: {
    url: 'sponsors',
    name: {
      plural: 'Sponsors',
      singular: 'Sponsor',
    },
  },
  order: {
    url: 'orders',
    name: {
      plural: 'Orders',
      singular: 'Order',
    },
  },
  pos: {
    url: 'pos',
    name: {
      plural: 'POS',
      singular: 'POS',
    },
  },
  tag: {
    url: 'tags',
    name: {
      plural: 'Tags',
      singular: 'Tag',
    },
  },
  orgSettings: {
    url: 'settings',
    name: {
      plural: 'Settings',
      singular: 'Setting',
    },
  },
  benefit: {
    url: 'benefits',
    name: {
      plural: 'Benefits',
      singular: 'Benefit',
    },
  },
}

// client paths
export const paths = {
  org: {
    ORGS: () => '/orgs',
    HOME: osid => `/orgs/${osid}`,
    PROGRAMS: osid => `/orgs/${osid}/programs`,
    PROGRAM: (osid, id) => `/orgs/${osid}/programs/${id}`,
    CREATE_PROGRAM: osid => `/orgs/${osid}/programs/create`,
    RESUME_DRAFT: (osid, id) => `/orgs/${osid}/programs/create/${id}`,
    OVERVIEW: (osid, id) => `/orgs/${osid}/programs/${id}/overview`,
    BOOKINGS: (osid, id) => `/orgs/${osid}/programs/${id}/bookings`,
    RESERVATIONS: (osid, id) => `/orgs/${osid}/programs/${id}/reservations`,
    PROGRAM_BENEFITS: (osid, id) => `/orgs/${osid}/programs/${id}/benefits`,
    PROGRAM_TAGS: (osid, id) => `/orgs/${osid}/programs/${id}/tags`,
    PROGRAM_POSTS: (osid, id) => `/orgs/${osid}/programs/${id}/posts`,
    PROGRAM_POST: (osid, id, postId) => `/orgs/${osid}/programs/${id}/posts/${postId}`,
    NEW_PROGRAM_POST: (osid, id) => `/orgs/${osid}/programs/${id}/posts/new`,
    EDIT_PROGRAM_POST: (osid, sid, postId) => `/orgs/${osid}/programs/${sid}/posts/${postId}/edit`,
    DUPLICATE: (osid, id) => `/orgs/${osid}/programs/${id}/duplicate`,
    PROGRAM_SETTINGS: (osid, id) => `/orgs/${osid}/programs/${id}/settings`,
    LOCATIONS: osid => `/orgs/${osid}/locations`,
    LOCATION: (osid, id) => `/orgs/${osid}/locations/${id}`,
    CREATE_LOCATION: osid => `/orgs/${osid}/locations/new`,
    EDIT_LOCATION: (osid, id) => `/orgs/${osid}/locations/${id}/edit`,
    CLUBS: osid => `/orgs/${osid}/clubs`,
    CLUB: (osid, sid) => `/orgs/${osid}/clubs/${sid}`,
    CLUB_POSTS: (osid, sid) => `/orgs/${osid}/clubs/${sid}/posts`,
    CLUB_PROGRAMS: (osid, sid) => `/orgs/${osid}/clubs/${sid}/programs`,
    CLUB_SHOP: (osid, sid) => `/orgs/${osid}/clubs/${sid}/shop`,
    CLUB_MEMBERS: (osid, sid) => `/orgs/${osid}/clubs/${sid}/members`,
    CLUB_VIDEOS: (osid, sid) => `/orgs/${osid}/clubs/${sid}/videos`,
    NEW_CLUB_VIDEO: (osid, sid) => `/orgs/${osid}/clubs/${sid}/videos/new`,
    EDIT_CLUB_VIDEO: (osid, sid, videoId) => `/orgs/${osid}/clubs/${sid}/videos/${videoId}/edit`,
    CLUB_SETTINGS: (osid, sid) => `/orgs/${osid}/clubs/${sid}/settings`,
    NEW_CLUB: osid => `/orgs/${osid}/clubs/new`,
    EDIT_CLUB: (osid, sid) => `/orgs/${osid}/clubs/${sid}/edit`,
    CLUB_POST: (osid, sid, postId) => `/orgs/${osid}/clubs/${sid}/posts/${postId}`,
    NEW_CLUB_POST: (osid, sid) => `/orgs/${osid}/clubs/${sid}/posts/new`,
    EDIT_CLUB_POST: (osid, sid, postId) => `/orgs/${osid}/clubs/${sid}/posts/${postId}/edit`,
    PRODUCTS: osid => `/orgs/${osid}/products`,
    PRODUCT: (osid, productId) => `/orgs/${osid}/products/${productId}`,
    NEW_PRODUCT: osid => `/orgs/${osid}/products/new`,
    EDIT_PRODUCT: (osid, productId) => `/orgs/${osid}/products/${productId}/edit`,
    ROLES: osid => `/orgs/${osid}/roles`,
    ROLE: (osid, roleId) => `/orgs/${osid}/roles/${roleId}`,
    SPONSORS: osid => `/orgs/${osid}/sponsors`,
    SPONSOR: (osid, id) => `/orgs/${osid}/sponsors/${id}`,
    EDIT_SPONSOR: (osid, id) => `/orgs/${osid}/sponsors/${id}/edit`,
    NEW_SPONSOR: osid => `/orgs/${osid}/sponsors/new`,
    ORDERS: osid => `/orgs/${osid}/orders`,
    ORDER: (osid, orderId) => `/orgs/${osid}/orders/${orderId}`,
    POS: osid => `/orgs/${osid}/pos`,
    ORG_TAGS: osid => `/orgs/${osid}/tags`,
    ORG_TAG: (osid, sig) => `/orgs/${osid}/tags/${sig}`,
    NEW_ORG_TAG: osid => `/orgs/${osid}/tags/new`,
    EDIT_ORG_TAG: (osid, sig) => `/orgs/${osid}/tags/${sig}/edit`,
    ORG_SETTINGS: osid => `/orgs/${osid}/settings`,
  },
  partner: {
    LOGIN: () => '/login',
  },
  admin: {
    HOME: () => '/admin',
    LOGIN: () => '/admin/login',
    DASHBOARD: () => '/admin/dashboard',
    ATTRIBUTES: () => '/admin/attributes',
    BENEFICIARY_GROUP: (sig, beneficiaryGroupId) =>
      `/admin/benefits/${sig}/beneficiary-groups/${beneficiaryGroupId}`,
    BENEFITS: () => '/admin/benefits',
    BENEFIT: sig => `/admin/benefits/${sig}`,
    USERS: () => '/admin/users',
    USER: id => `/admin/users/${id}`,
    ORGS: () => `/admin/orgs`,
    ORG: sig => `/admin/orgs/${sig}`,
    ORG_VENUE_UPLOAD: orgSig => `/admin/orgs/${orgSig}/venue-upload`,
    ORG_VENUE: (orgSig, venueSig) => `/admin/orgs/${orgSig}/venues/${venueSig}`,
    VENUE: venueSig => `/admin/venues/${venueSig}`,
    PROS: () => '/admin/pros',
    NEW_PRO_PAGE: userId => `/admin/pros/${userId}`,
    PRO_PAGE: pageId => `/admin/pros/${pageId}`,
    PROGRAMS: () => '/admin/programs',
    PROGRAM: id => `/admin/programs/${id}`,
    CUSTOM_LANDING_PAGES: () => `/admin/clp`,
    CUSTOM_EMAIL_CAPTURES: () => `/admin/cec`,
    CUSTOM_EMAIL_CAPTURE: id => `/admin/cec/${id}`,
    INTERNAL_TAGS: () => `/admin/it`,
    PERMITS: () => `/admin/permits`,
    BTL: () => `/admin/btl`,
    USER_PERMITS: () => `/admin/permits/user-permits`,
    USER_PERMIT: id => `/admin/permits/user-permits/${id}`,
    PURCHASABLE_PERMITS: () => `/admin/permits/purchasable`,
    NYC_PERMIT_ACCOUNTS: () => `/admin/permits/nyc-permit-accounts`,
    NYC_PERMIT_ACCOUNT: id => `/admin/permits/nyc-permit-accounts/${id}`,
    NYC_PERMIT_CARDS: () => `/admin/permits/nyc-permit-cards`,
    NYC_PERMIT_CARD: id => `/admin/permits/nyc-permit-cards/${id}`,
    PACKAGES: () => `/admin/packages`,
    PACKAGE: id => `/admin/packages/${id}`,
    PACKAGE_SET: id => `/admin/package-sets/${id}`,
    PACKAGE_SETS: () => `/admin/package-sets`,
    USER_PACKAGE: id => `/admin/user-packages/${id}`,
    billing: {
      PROMO_CODES: () => `/admin/promo-codes`,
      PROMO_CODE: id => `/admin/promo-codes/${id}`,
      INVOICES: () => `/admin/invoices`,
      INVOICE: id => `/admin/invoices/${id}`,
    },
    emails: {
      INDEX: () => `/admin/emails`,
      PREVIEW: id => `/admin/emails/${id}`,
      RALLY_REPORT: () => `/admin/emails/break-report-rally`,
      COMPETITION_REPORT: () => `/admin/emails/break-report-competition`,
    },
  },
}

// client path patterns
export const patterns = {
  admin: {
    emails: {
      EMAIL_PREVIEW: id => `/admin/emails/:id`,
    },
  },
  org: {
    GENERAL_FEATURE: () => `/orgs/:osid/:feature`,
    PROGRAM: () => `/orgs/:osid/programs/:id`,
    OVERVIEW: () => `/orgs/:osid/programs/:id/overview`,
    BOOKINGS: () => `/orgs/:osid/programs/:id/bookings`,
    RESERVATIONS: () => `/orgs/:osid/programs/:id/reservations`,
    PROGRAM_SETTINGS: () => `/orgs/:osid/programs/:id/settings`,
    PROGRAM_POSTS: () => `/orgs/:osid/programs/:id/posts`,
    PROGRAM_TAGS: () => `/orgs/:osid/programs/:id/tags`,
    PROGRAM_BENEFITS: () => `/orgs/:osid/programs/:id/benefits`,
    NEW_PROGRAM_POST: () => `/orgs/:osid/programs/:id/posts/new`,
    DUPLICATE: () => `/orgs/:osid/programs/:id/duplicate`,
    LOCATIONS: () => `/orgs/:osid/locations`,
    LOCATION: () => `/orgs/:osid/locations/:id`,
    CLUB_OVERVIEW: () => `/orgs/:osid/clubs/:id`,
    CLUB_POSTS: () => `/orgs/:osid/clubs/:id/posts`,
    CLUB_PROGRAMS: () => `/orgs/:osid/clubs/:id/programs`,
    CLUB_SHOP: () => `/orgs/:osid/clubs/:id/shop`,
    CLUB_MEMBERS: () => `/orgs/:osid/clubs/:id/members`,
    CLUB_VIDEOS: () => `/orgs/:osid/clubs/:id/videos`,
    CLUB_SETTINGS: () => `/orgs/:osid/clubs/:id/settings`,
  },
  PRO: () => `/admin/pros/:id`,
  USER: () => `/admin/users/:id`,
}

// server urls
export const urls = {
  SITE_URL: () => config.siteUrl,
  API_BASE_URL: () => config.apiUrl,
  MAIN_APP_URL: () => config.mainAppUrl,
  BOS_URL: () => config.bosUrl,
  api: {
    PRO_PROGRAMS: id => `/api/v1/pros/pages/${id}/programs`,
    WALMART_PRODUCT_LOOKUP: itemId => `/api/v1/wmt-products/${itemId}`,
  },
  org: {
    ORGS: () => '/orgs',
    ORG: osid => `/orgs/${osid}`,
    ORG_SETTINGS: osid => `/orgs/${osid}/settings`,
    CONSTS: osid => `/orgs/${osid}/consts`,
    CONSTS_BY_NAME: (osid, name) => `/orgs/${osid}/consts/${name}`,
    USERS: osid => `/orgs/${osid}/search/users`,
    CLUBS: osid => `/orgs/${osid}/clubs`,
    CLUB: (osid, id) => `/orgs/${osid}/clubs/${id}`,
    CLUB_MEMBERS_CSV: (osid, id) => `/orgs/${osid}/clubs/${id}/members.csv`,
    CLUB_MEMBERS: (osid, id) => `/orgs/${osid}/clubs/${id}/members`,
    CLUB_MEMBER: (osid, id, userId) => `/orgs/${osid}/clubs/${id}/members/${userId}`,
    CLUB_POSTS: (osid, id) => `/orgs/${osid}/clubs/${id}/posts`,
    CLUB_POST: (osid, id, postId) => `/orgs/${osid}/clubs/${id}/posts/${postId}`,
    CLUB_PINNED_POSTS: (osid, id) => `/orgs/${osid}/clubs/${id}/pinned-posts`,
    PIN_CLUB_POST: (osid, id, postId) => `/orgs/${osid}/clubs/${id}/posts/${postId}/pin`,
    UNPIN_CLUB_POST: (osid, id, postId) => `/orgs/${osid}/clubs/${id}/posts/${postId}/unpin`,
    CLUB_POST_COMMENTS: (osid, id, postId) => `/orgs/${osid}/clubs/${id}/posts/${postId}/comments`,
    CLUB_POST_COMMENT: (osid, id, postId, cmtId) =>
      `/orgs/${osid}/clubs/${id}/posts/${postId}/comments/${cmtId}`,
    CLUB_POST_COMMENT_REPLIES: (osid, id, postId, cmtId) =>
      `/orgs/${osid}/clubs/${id}/posts/${postId}/comments/${cmtId}/replies`,
    CLUB_SETTINGS: (osid, id) => `/orgs/${osid}/clubs/${id}/settings`,
    CLUB_PRODUCTS: (osid, id) => `/orgs/${osid}/clubs/${id}/products`,
    CLUB_VIDEOS: (osid, id) => `/orgs/${osid}/clubs/${id}/videos`,
    CLUB_VIDEO: (osid, id, videoId) => `/orgs/${osid}/clubs/${id}/videos/${videoId}`,
    CREATE_PROGRAM: osid => `/orgs/${osid}/programs`,
    PROGRAMS: osid => `/orgs/${osid}/programs`,
    PROGRAM: (osid, id) => `/orgs/${osid}/programs/${id}`,
    SEARCH_PROGRAMS: osid => `/orgs/${osid}/search/programs`,
    SEARCH_UPCOMING_PROGRAMS: osid => `/orgs/${osid}/search/programs/upcoming`,
    SEARCH_PAST_PROGRAMS: osid => `/orgs/${osid}/search/programs/past`,
    UPCOMING_PROGRAMS: osid => `/orgs/${osid}/programs/upcoming`,
    PAST_PROGRAMS: osid => `/orgs/${osid}/programs/past`,
    DRAFT_PROGRAMS: osid => `/orgs/${osid}/programs/draft`,
    PROGRAM_ATTRIBUTES: osid => `/orgs/${osid}/attributes/program`,
    CLUB_ATTRIBUTES: osid => `/orgs/${osid}/attributes/club`,
    FINALIZE_DRAFT_PROGRAM: (osid, id) => `/orgs/${osid}/programs/${id}/finalize`,
    DUPLICATE_PROGRAM: (osid, programId) => `/orgs/${osid}/programs/${programId}/duplicate`,
    PROGRAM_POSTS: (osid, id) => `/orgs/${osid}/programs/${id}/posts`,
    PROGRAM_POST: (osid, id, postId) => `/orgs/${osid}/programs/${id}/posts/${postId}`,
    PROGRAM_TAGS: (osid, id) => `/orgs/${osid}/programs/${id}/tags`,
    PROGRAM_PINNED_POSTS: (osid, id) => `/orgs/${osid}/programs/${id}/pinned-posts`,
    PROGRAM_BENEFITS: (osid, id) => `/orgs/${osid}/programs/${id}/associated-benefits`,
    PROGRAM_BENEFIT: (osid, id, benefitProgramId) =>
      `/orgs/${osid}/programs/${id}/associated-benefits/${benefitProgramId}`,
    PROGRAM_BOOKINGS_CSV: (osid, id) => `/orgs/${osid}/programs/${id}/bookings.csv`,
    PIN_PROGRAM_POST: (osid, id, postId) => `/orgs/${osid}/programs/${id}/posts/${postId}/pin`,
    UNPIN_PROGRAM_POST: (osid, id, postId) => `/orgs/${osid}/programs/${id}/posts/${postId}/unpin`,
    PROGRAM_POST_COMMENTS: (osid, id, postId) =>
      `/orgs/${osid}/programs/${id}/posts/${postId}/comments`,
    PROGRAM_POST_COMMENT: (osid, id, postId, cmtId) =>
      `/orgs/${osid}/programs/${id}/posts/${postId}/comments/${cmtId}`,
    PROGRAM_POST_COMMENT_REPLIES: (osid, id, postId, cmtId) =>
      `/orgs/${osid}/programs/${id}/posts/${postId}/comments/${cmtId}/replies`,
    PROGRAM_BOOKINGS: (osid, programId) => `/orgs/${osid}/programs/${programId}/bookings`,
    CANCEL_BOOKING: (osid, programId, bookingId) =>
      `/orgs/${osid}/programs/${programId}/bookings/${bookingId}/cancel`,
    PROGRAM_BOOKING_NOTE: (osid, programId, bookingId) =>
      `/orgs/${osid}/programs/${programId}/bookings/${bookingId}/note`,
    PROGRAM_WAITLIST: (osid, programId) => `/orgs/${osid}/programs/${programId}/waitlist`,
    PROGRAM_WAITLIST_USER: (osid, programId, userId) =>
      `/orgs/${osid}/programs/${programId}/waitlist/${userId}`,
    PROGRAM_RES_RESERVATIONS: (osid, programId) =>
      `/orgs/${osid}/programs/${programId}/res-reservations`,
    PROGRAM_RES_RESERVATION: (osid, programId, resReservationId) =>
      `/orgs/${osid}/programs/${programId}/res-reservations/${resReservationId}`,
    LOCATIONS: osid => `/orgs/${osid}/locations`,
    LOCATION: (osid, id) => `/orgs/${osid}/locations/${id}`,
    ROLES: osid => `/orgs/${osid}/roles`,
    ROLE: (osid, roleId) => `/orgs/${osid}/roles/${roleId}`,
    ROLE_ASSIGN: (osid, roleId) => `/orgs/${osid}/roles/${roleId}/assign-user`,
    ROLE_UNASSIGN: (osid, roleId) => `/orgs/${osid}/roles/${roleId}/unassign-user`,
    SEARCH_CLUB_FILTERS: osid => `/orgs/${osid}/search/clubs/filters`,
    SEARCH_CLUBS: osid => `/orgs/${osid}/search/clubs`,
    BENEFITS: osid => `/orgs/${osid}/benefits`,
    BENEFICIARY_GROUPS: (osid, benefitSig) =>
      `/orgs/${osid}/benefits/${benefitSig}/beneficiary-groups`,
    SPONSORS: osid => `/orgs/${osid}/sponsors`,
    SPONSOR: (osid, id) => `/orgs/${osid}/sponsors/${id}`,
    TAGS: osid => `/orgs/${osid}/tags`,
    TAG: (osid, sig) => `/orgs/${osid}/tags/${sig}`,
    PRODUCTS: osid => `/orgs/${osid}/products`,
    PRODUCT: (osid, productId) => `/orgs/${osid}/products/${productId}`,
    PRODUCT_VARIANTS: (osid, productId) => `/orgs/${osid}/products/${productId}/variants`,
    PRODUCT_VARIANT: (osid, productId, variantId) =>
      `/orgs/${osid}/products/${productId}/variants/${variantId}`,
    PRODUCT_VARIANT_STOCK: (osid, productId, variantId) =>
      `/orgs/${osid}/products/${productId}/variants/${variantId}/stock`,
    ADD_VARIANT_STOCK: (osid, productId, variantId) =>
      `/orgs/${osid}/products/${productId}/variants/${variantId}/add-stock`,
    REMOVE_VARIANT_STOCK: (osid, productId, variantId) =>
      `/orgs/${osid}/products/${productId}/variants/${variantId}/remove-stock`,
    VARIANT_OPTIONS: osid => `/orgs/${osid}/variant-options`,
    PRODUCT_VARIANT_OPTIONS: (osid, productId) =>
      `/orgs/${osid}/products/${productId}/variant-options`,
    PRODUCT_CLUBS: (osid, productId) => `/orgs/${osid}/products/${productId}/clubs`,
    ORDERS: osid => `/orgs/${osid}/orders`,
    ORDER: (osid, orderId) => `/orgs/${osid}/orders/${orderId}`,
    ORDER_ITEM: (osid, orderId, itemId) => `/orgs/${osid}/orders/${orderId}/items/${itemId}`,

    TERMINAL_CONNECTION_TOKEN: osid => `/orgs/${osid}/terminal/connection-token`,
    TERMINAL_START_ORDER: osid => `/orgs/${osid}/terminal/start-order`,
    ESTIMATE_INVOICE: osid => `/orgs/${osid}/estimate-invoice`,
    INVOICE: (osid, invoiceId) => `/orgs/${osid}/invoices/${invoiceId}`,
    CAPTURE_PAYMENT: (osid, paymentIntentId) => `/orgs/${osid}/capture-payment/${paymentIntentId}`,
    EMAIL_RECEIPT: (osid, paymentIntentId) => `/orgs/${osid}/email-receipt/${paymentIntentId}`,
    NET_VENUES: osid => `/orgs/${osid}/network/venues`,
    NET_VENUE_AVAILABLE_RESOURCES: (osid, venueId) =>
      `/orgs/${osid}/network/venues/${venueId}/available-resources`,
  },
  partner: {
    USERS_SEARCH: () => `/users/search`,
  },
  admin: {
    USERS: () => '/admin/users',
    USER: id => `/admin/users/${id}`,
    USER_TYPE: id => `/admin/users/${id}/type`,
    USER_LEVEL: id => `/admin/users/${id}/level`,
    USER_PROFILE_IMAGE: id => `/admin/users/${id}/profile-image`,
    USER_PROGRAM_BOOKINGS: id => `/admin/users/${id}/program-bookings`,
    USER_PACKAGES: id => `/admin/users/${id}/packages`,
    USER_PACKAGE: userPackageId => `/admin/user-packages/${userPackageId}`,
    USER_PACKAGE_ITEM: (userPackageId, userPackageItemId) =>
      `/admin/user-packages/${userPackageId}/items/${userPackageItemId}`,
    USER_PACKAGE_ITEM_USES: (userPackageId, userPackageItemId) =>
      `/admin/user-packages/${userPackageId}/items/${userPackageItemId}/uses`,
    USER_BENEFICIARIES: id => `/admin/users/${id}/beneficiaries`,
    SEARCH_BENEFITS: () => `/admin/search-benefits`,
    BENEFITS: () => `/admin/benefits`,
    BENEFIT: sig => `/admin/benefits/${sig}`,
    BENEFIT_VENUES: sig => `/admin/benefits/${sig}/venues`,
    BENEFIT_VENUE: (sig, bvid) => `/admin/benefits/${sig}/venues/${bvid}`,
    BENEFIT_BENEFICIARY: (benefitSig, beneficiaryId) =>
      `/admin/benefits/${benefitSig}/beneficiaries/${beneficiaryId}`,
    BENEFIT_BENEFICIARY_GROUPS: id => `/admin/benefits/${id}/beneficiary-groups`,
    BENEFIT_BENEFICIARY_GROUP: (benefitSig, beneficiaryGroupId) =>
      `/admin/benefits/${benefitSig}/beneficiary-groups/${beneficiaryGroupId}`,
    BENEFIT_BENEFICIARY_GROUP_USERS: (benefitSig, beneficiaryGroupId) =>
      `/admin/benefits/${benefitSig}/beneficiary-groups/${beneficiaryGroupId}/users`,
    QUICK_SEARCH_CLUBS: () => `/admin/search-clubs`,
    CUSTOMER_ORGS: id => `/admin/users/${id}/user-customers`,
    CONSTS_BY_NAME: name => `/admin/consts/${name}`,
    ALL_CONSTS: () => `/admin/consts`,
    USER_CLUB_MEMBERSHIPS: id => `/admin/users/${id}/club-memberships`,
    USER_WAITLISTS: id => `/admin/users/${id}/waitlists`,
    USER_ORG_TEAM_MEMBERSHIPS: id => `/admin/users/${id}/org-team-memberships`,
    USER_ORG_TEAM_MEMBERSHIP: (id, membershipId) =>
      `/admin/users/${id}/org-team-memberships/${membershipId}`,
    USER_VENUE_TEAM_MEMBERSHIPS: id => `/admin/users/${id}/venue-team-memberships`,
    USER_VENUE_TEAM_MEMBERSHIP: (id, membershipId) =>
      `/admin/users/${id}/venue-team-memberships/${membershipId}`,
    USER_USER_PERMITS: id => `/admin/users/${id}/permits`,
    USER_ADMIN_CANCEL_PROGRAM_BOOKINGS: id => `/admin/users/${id}/cancel-program-bookings`,
    USER_ADMIN_CANCEL_RES_RESERVATION_BOOKINGS: id =>
      `/admin/users/${id}/cancel-res-reservation-bookings`,
    GENERATE_USER_PASSWORD_RESET_LINK: id => `/admin/users/${id}/password-reset-link`,
    SEARCH_VENUES: () => '/admin/search-venues',
    VENUES: () => '/admin/venues',
    VENUE: sig => `/admin/venues/${sig}`,
    VENUE_SETTINGS: sig => `/admin/venues/${sig}/settings`,
    VENUE_RESOURCES: sig => `/admin/venues/${sig}/resources`,
    VENUE_HOMEPAGE_SETTINGS: sig => `/admin/venues/${sig}/homepage-settings`,
    VENUE_HOMEPAGE_CALLOUTS: sig => `/admin/venues/${sig}/homepage-callouts`,
    VENUE_HOMEPAGE_CALLOUT: (sig, calloutId) =>
      `/admin/venues/${sig}/homepage-callouts/${calloutId}`,
    VENUE_AVAILABLE_LESSONS: sig => `/admin/venues/${sig}/available-lessons`,
    VENUE_AVAILABLE_LESSON: (sig, availableLessonId) =>
      `/admin/venues/${sig}/available-lessons/${availableLessonId}`,
    BENEFIT_VENUE_PERMITTED_RESOURCES: id => `/admin/benefit-venues/${id}/permitted-resources`,
    BENEFIT_VENUE_PERMITTED_BENEFICIARY_GROUPS: id =>
      `/admin/benefit-venues/${id}/permitted-beneficiary-groups`,
    BENEFIT_VENUE_SETTINGS: id => `/admin/benefit-venues/${id}/settings`,
    SEARCH_ORGS: () => '/admin/search-orgs',
    ORGS: () => '/admin/orgs',
    ORG: sig => `/admin/orgs/${sig}`,
    ORG_TEAM_MEMBERS: sig => `/admin/orgs/${sig}/members`,
    ORG_TEAM_MEMBER: (sig, membershipId) => `/admin/orgs/${sig}/members/${membershipId}`,
    ORG_VENUES: sig => `/admin/orgs/${sig}/venues`,
    PRO_PAGE: id => `/admin/pros/pages/${id}`,
    PRO_PAGES: () => '/admin/pros/pages',
    PRO_PAGE_IMAGES: id => `/admin/pros/pages/${id}/images`,
    PRO_PAGE_IMAGE: (id, imageId) => `/admin/pros/pages/${id}/images/${imageId}`,
    PRO_SHOP: pageId => `/admin/pros/pages/${pageId}/shop`,
    PRO_SHOP_ITEMS: pageId => `/admin/pros/pages/${pageId}/shop/items`,
    PRO_SHOP_ITEM: (pageId, itemId) => `/admin/pros/pages/${pageId}/shop/items/${itemId}`,
    PRO_SHOP_ITEM_IMAGES: (pageId, itemId) =>
      `/admin/pros/pages/${pageId}/shop/items/${itemId}/images`,
    PRO_SHOP_ITEM_IMAGE: (pageId, itemId, imageId) =>
      `/admin/pros/pages/${pageId}/shop/items/${itemId}/images/${imageId}`,
    PROGRAM: id => `/admin/programs/${id}`,
    PROGRAMS: () => `/admin/programs`,
    PROGRAMS_SEARCH: () => '/admin/programs/search',
    CANCEL_PROGRAM: id => `/admin/programs/${id}/cancel`,
    QUICK_SEARCH_PROGRAMS: () => '/admin/search-programs',
    BOOKABLE_PROGRAMS: () => '/admin/programs/bookable',
    USERS_SEARCH: () => '/admin/users/search',
    ORG_FEE_RULE: (orgSig, feeRuleId) => `/admin/orgs/${orgSig}/fee-rules/${feeRuleId}`,
    ORG_FEE_RULES: orgSig => `/admin/orgs/${orgSig}/fee-rules`,
    CUSTOM_LANDING_PAGES: () => `/admin/clp`,
    CUSTOM_LANDING_PAGE: id => `/admin/clp/${id}`,
    CUSTOM_EMAIL_CAPTURES: () => `/admin/cec`,
    CUSTOM_EMAIL_CAPTURE: id => `/admin/cec/${id}`,
    INTERNAL_TAGS: () => `/admin/it/`,
    INTERNAL_TAG: id => `/admin/it/${id}`,
    ATTRIBUTES: () => `/admin/attributes`,
    ATTRIBUTE: id => `/admin/attributes/${id}`,
    PROMO_CODES: () => `/admin/promo-codes`,
    PROMO_CODE: id => `/admin/promo-codes/${id}`,
    INVOICES: () => `/admin/invoices/`,
    INVOICE: id => `/admin/invoices/${id}`,
    INVOICE_NOTE: id => `/admin/invoices/${id}/note`,
    PAYMENT_NOTE: id => `/admin/payments/${id}/note`,
    REFUND_PAYMENT: id => `/admin/payments/${id}/refunds`,
    LINE_ITEM_NOTE: id => `/admin/line-items/${id}/note`,
    NYC_PERMIT_ACCOUNTS: () => `/admin/permits/nyc-permit-accounts`,
    NYC_PERMIT_ACCOUNT: id => `/admin/permits/nyc-permit-accounts/${id}`,
    NYC_PERMIT_CARDS: () => `/admin/permits/nyc-permit-cards`,
    NYC_PERMIT_CARD: id => `/admin/permits/nyc-permit-cards/${id}`,
    PURCHASABLE_PERMITS: () => `/admin/permits/purchasable`,
    PURCHASABLE_PERMIT: id => `/admin/permits/purchasable/${id}`,
    USER_PERMITS: () => `/admin/permits/user-permits`,
    USER_PERMITS_CSV: () => `/admin/permits/user-permits.csv`,
    USER_PERMIT: id => `/admin/permits/user-permits/${id}`,
    USER_PERMIT_NOTES: id => `/admin/permits/user-permits/${id}/notes`,
    USER_PERMIT_NYC_ACCOUNT: id => `/admin/permits/user-permits/${id}/nyc-account`,
    USER_PERMIT_NYC_CARD: id => `/admin/permits/user-permits/${id}/nyc-card`,
    USER_PERMIT_MANUAL_FULFILLMENT: id => `/admin/permits/user-permits/${id}/manual-fulfillment`,
    EXPLORE_CLUBS_POSITIONS: () => '/admin/explore-clubs-positions',
    EXPLORE_CLUBS_POSITION: clubId => `/admin/explore-clubs-positions/${clubId}`,
    REORDER_BTL_FEATURED_GEOS: () => `/admin/btl-featured-geos/reorder`,
    BTL_FEATURED_GEOS: () => `/admin/btl-featured-geos`,
    BTL_FEATURED_GEO: id => `/admin/btl-featured-geos/${id}`,
    BTL_FEATURED_FACILITIES: () => `/admin/btl-featured-facilities`,
    BTL_FEATURED_FACILITY: id => `/admin/btl-featured-facilities/${id}`,
    REORDER_BTL_FEATURED_FACILITIES: () => `/admin/btl-featured-facilities/reorder`,
    ALL_CLUBS: () => '/admin/clubs',
    PACKAGE: id => `/admin/packages/${id}`,
    PACKAGE_SETS: () => `/admin/package-sets`,
    PACKAGE_SET: id => `/admin/package-sets/${id}`,
    metrics: {
      PER_RESOURCE_BOOKINGS_BY_MONTH: () => `/admin/metrics/per-resource-bookings-by-month`,
      PER_RESOURCE_BOOKINGS_BY_MONTH_QUERY: () =>
        `/admin/metrics/per-resource-bookings-by-month/query`,
      PER_PARTICIPANT_BOOKINGS_BY_MONTH: () => `/admin/metrics/per-participant-bookings-by-month`,
      PER_PARTICIPANT_BOOKINGS_BY_MONTH_QUERY: () =>
        `/admin/metrics/per-participant-bookings-by-month/query`,
    },

    DOWNLOAD_INVENTORY_CAPACITY: ({ startDate, endDate }) =>
      `/admin/analytics/inventory-capacity?startDate=${startDate}&endDate=${endDate}`,
  },
  auth: {
    SELF: () => '/api/v1/user',
    LOGIN: () => '/api/v1/auth/login',
    LOGOUT: () => '/api/v1/auth/logout',
  },
}

export const cancellationPolicies = {
  ...descConst(
    '24_HOURS',
    '24 HOURS',
    'If you cancel 24 hours before the time of the event you will receive a full refund.',
  ),
  ...descConst(
    '48_HOURS',
    '48 HOURS',
    'If you cancel 48 hours before the time of the event you will receive a full refund.',
  ),
  ...descConst(
    '72_HOURS',
    '72 HOURS',
    'If you cancel at least 72 hours prior to the time of the event you will receive a full refund. If you cancel within 72 hour of the event, you will receive no refund.',
  ),
}

export const btlSports = {
  ...descConst('TENNIS', 'Tennis'),
  ...descConst('PICKLEBALL', 'Pickleball'),
  ...descConst('PADEL', 'Padel'),
  ...descConst('SQUASH', 'Squash'),
  // TODO once we have a BTB brand, move golf out of here (this means that btl venues would lose the ability to choose golf lessons)
  ...descConst('GOLF', 'Golf'),
}

export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'Washington, D.C.',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}
